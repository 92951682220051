import { useState, useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { useNavigate } from 'react-router-dom'
import { AAD_CLIENT_ID, MSAL_REDIRECT_URI, TEAMS_EXPORTER_API_BASE_URL } from '../config'
import { useAuth } from '../contexts/authContext'

const msalConfig = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: MSAL_REDIRECT_URI,
    postLogoutRedirectUri: MSAL_REDIRECT_URI
  }
}

export const pca = new PublicClientApplication(msalConfig)

export const useAzureLogin = (): any => {
  const [isInitialized, setIsInitialized] = useState(false)
  const navigate = useNavigate()
  const { currentUser, authenticatedRequest } = useAuth()

  // On mount, start the login flow with MSAL
  useEffect(() => {
    const init = async (): Promise<void> => {
      await pca.initialize()
      setIsInitialized(true)

      try {
        const response = await pca.handleRedirectPromise()
        if (response) {
          console.log('Respones from login: ', response)
        }
      } catch (error) {
        console.error(error)
      }
    }

    void init()
  }, [])

  useEffect(() => {
    if (currentUser) {
      console.log('current user has changed via the login flow:', currentUser)
    }
  }, [currentUser])

  const handleLogin = async (): Promise<void> => {
    if (!isInitialized) {
      console.error('MSAL client is not initialized yet.')
      return
    }

    const loginParams = {
      scopes: ['openid', 'profile', 'email', 'Chat.Read', 'Chat.ReadBasic', 'User.Read']
    }

    try {
      await pca.loginPopup(loginParams)
      await authenticatedRequest({
        method: 'get',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/user-me`
      })
      navigate('/list-chats')
    } catch (error) {
      console.error(error)
    }
  }

  return {
    handleLogin,
    isInitialized
  }
}
