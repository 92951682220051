import { Box, Menu, MenuItem, Typography, LinearProgress, Divider } from '@mui/material'
import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { UserTier } from '../../types/UserTier'
import StarIcon from '@mui/icons-material/Star'
import { useNavigate } from 'react-router-dom'
import { TEAMS_EXPORTER_API_BASE_URL } from '../../config'

const MAXIMUM_FREE_CHAT_EXPORTS = 5

interface UserMenuProps {
  isOpen: boolean
  anchorEl: HTMLElement | null
  onClose: () => void
  onLogout: () => void
  onLogin: () => void
  isLoggedIn: boolean
  username: string
}

const calculatePercentageUsed = (isLoggedIn: boolean, numberOfChatsExported: number): number =>
  isLoggedIn ? (numberOfChatsExported / MAXIMUM_FREE_CHAT_EXPORTS) * 100 : 0

const generateMenuItems = (isLoggedIn: boolean, userTier: UserTier): Array<string | null> => [
  ...(isLoggedIn ? [userTier !== UserTier.PREMIUM ? 'Upgrade to Premium' : 'Cancel Premium', 'Logout'] : ['Login'])
].filter(Boolean)

export const UserMenu: React.FC<UserMenuProps> = ({ isOpen, anchorEl, onClose, onLogout, onLogin, isLoggedIn, username }) => {
  const { currentUser, authenticatedRequest, setUser } = useAuth()
  const navigate = useNavigate()

  const percentageUsed = calculatePercentageUsed(isLoggedIn, Math.min(currentUser.numberOfChatsExported, MAXIMUM_FREE_CHAT_EXPORTS))
  const menuItems = generateMenuItems(isLoggedIn, currentUser.userTier)

  const handleMenuItemClick = async (item: string): Promise<void> => {
    if (item === 'Logout') onLogout()
    else if (item === 'Login') onLogin()
    else if (item === 'Upgrade to Premium') navigate('/payment')
    else if (item === 'Cancel Premium') {
      try {
        const response = await authenticatedRequest({
          method: 'POST',
          url: `${TEAMS_EXPORTER_API_BASE_URL}/cancel-subscription` // API endpoint for canceling premium
        })
        if (response?.status === 200) {
          // Update the user tier to free after successfully canceling premium
          setUser((prevUser: any) => ({
            ...prevUser,
            userTier: UserTier.FREE // assuming 'FREE' is the non-premium tier
          }))
        }
      } catch (error) {
        console.error('Error cancelling premium:', error)
      }
    } else onClose()
  }

  return (
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isOpen}
        onClose={onClose}
      >
        {isLoggedIn && (
      <MenuItem>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Typography variant="subtitle1" color="textSecondary">Logged in as: {username}</Typography> {/* Grayed out text */}
          <Box display="flex" justifyContent="center" my={0.5}>
            <Divider style={{ width: '90%' }} />
          </Box>
          {currentUser.userTier === UserTier.PREMIUM && <StarIcon color="primary" sx={{ color: '#6666FF', marginLeft: 1, marginTop: 1 }} />}
          {currentUser.userTier !== UserTier.PREMIUM && (
            <>
              <Typography variant="body2">
                {Math.min(currentUser.numberOfChatsExported, MAXIMUM_FREE_CHAT_EXPORTS)} / {MAXIMUM_FREE_CHAT_EXPORTS} free chat exports used
              </Typography>
              <Box width="100%" mt={1} mb={1}>
                <LinearProgress variant="determinate" value={percentageUsed} sx={{
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#6666FF'
                  }
                }} />
              </Box>
            </>
          )}
        </Box>
      </MenuItem>
        )}
    <Box display="flex" justifyContent="center" my={1}>
      <Divider style={{ width: '90%' }} />
    </Box>
    {menuItems.map((item: any) => (
      item
        ? (
          <MenuItem key={item} onClick={() => { void handleMenuItemClick(item) }}>
            <Typography textAlign="center">{item}</Typography>
            {item === 'Upgrade to Premium' && <StarIcon color="primary" sx={{ color: '#6666FF', marginLeft: 1 }} />}
          </MenuItem>
          )
        : null
    ))}
  </Menu>
  )
}
