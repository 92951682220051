/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import { createContext, useContext } from 'react'
import { type UserTier } from '../types/UserTier'

export interface User {
  username?: string
  userTier?: UserTier
  name?: string
  numberOfChatsExported?: number
}

interface AuthContextType {
  currentUser: User
  setUser: (user: User) => void
  authenticatedRequest: (requestConfig: AxiosRequestConfig) => Promise<AxiosResponse<any> | undefined>
}

const defaultAuthValue: AuthContextType = {
  currentUser: {},
  setUser: (user: User) => {},
  authenticatedRequest: async (requestConfig: any) => {
    return await new Promise((resolve) => {
      resolve({
        data: {},
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        request: requestConfig
      } as AxiosResponse<any>)
    })
  }
}

const AuthContext = createContext(defaultAuthValue)

export const useAuth = (): any => {
  return useContext(AuthContext)
}

export default AuthContext
